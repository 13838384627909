export interface RuleViolation {
	name: string;
	description: string;
	type: "actual" | "roblox" | "none";
}

export const ruleViolations: RuleViolation[] = [
	{
		name: "Exploiting / Hacking",
		description:
			"The user is performing actions in the game that can only be done using a modified version of Roblox. The actions could include extremely high jumping, faster walking speeds or other irregular forms of activity.",
		type: "actual",
	},
	{
		name: "Insulting or disrespectful Behavior",
		description:
			"The user is disrespectful or insults other people using the chat features of the game.",
		type: "roblox",
	},
	{
		name: "Fail Roleplay or Teaming",
		description: "The user is teaming with others or fails to roleplay.",
		type: "none",
	},
	{
		name: "Impersonating a Staff Member",
		description:
			"The user is pretending to be a staff member to receive recognition or advantages in the game.",
		type: "actual",
	},
	{
		name: "Intentionally using Game Bugs",
		description:
			"The user is using game bugs to their advantage and affects other players with it.",
		type: "actual",
	},
	{
		name: "Placing Items (like Tapes, Cones or Stop Sticks) offensively",
		description:
			"The user is using placeable tools to draw symbols that are not allowed on Roblox.",
		type: "actual",
	},
	{
		name: "Roblox Terms of Service",
		description:
			"The user is comitting a Roblox Terms of Service violation which is not listed here.",
		type: "roblox",
	},
];
