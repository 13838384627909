import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authorized from "./routes/authorized";
import Default from "./routes/default";
import Home from "./routes/home";
import Imprint from "./routes/imprint";
import PlayerReport from "./routes/playerReport";
import PrivacyPolicy from "./routes/privacyPolicy";
import React from "react";
import ReactDOM from "react-dom/client";
import TermsOfService from "./routes/termsOfService";
import UnbanRequest from "./routes/unbanRequest";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/player-report" element={<PlayerReport />} />
				<Route path="/unban-request" element={<UnbanRequest />} />
				<Route path="/terms-of-service" element={<TermsOfService />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/imprint" element={<Imprint />} />
				<Route path="/authorized" element={<Authorized />} />
				<Route path="*" element={<Default />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
