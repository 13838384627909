import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Header from "../components/header";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default function Home() {
	return (
		<div>
			<Header />
			<Container>
				<Row className="no-gutters">
					<Col xs={12} lg={6} className="mb-4 d-flex align-items-stretch">
						<Card>
							<Card.Img variant="top" src="/img/play-card.png" />
							<Card.Body>
								<Card.Title>About Emergency Hamburg</Card.Title>
								<Card.Text>
									Emergency Hamburg is an emergency services role-playing game on the Roblox
									platform, where you can be a police officer, citizen, bus driver, car mechanic,
									truck driver, firefighter, medic, or criminal in the German city of Hamburg.
								</Card.Text>
							</Card.Body>
							<Card.Footer>
								<Button
									variant="success"
									href="https://www.roblox.com/games/7711635737"
									target="_blank">
									<i className="bi bi-play-circle-fill" /> Play now on Roblox
								</Button>
							</Card.Footer>
						</Card>
					</Col>
					<Col xs={12} lg={6} className="d-flex align-items-stretch">
						<Row className="no-gutters">
							<Col xs={12} className="mb-4">
								<Card>
									<Card.Body>
										<Card.Title>Information</Card.Title>
										<Card.Text>
											If you are interested in reading more about Emergency Hamburg, you can visit
											our wiki or take a look at our latest updates.
										</Card.Text>
									</Card.Body>
									<Card.Footer>
										<Stack direction="horizontal" gap={2}>
											<Button
												variant="success"
												href="https://wiki.emergency-hamburg.com/"
												target="_blank">
												<i className="bi bi-info-circle-fill" /> Wiki
											</Button>
											<DropdownButton
												title={
													<>
														<i className="bi bi-newspaper" /> Latest Updates
													</>
												}>
												<Dropdown.Item href="https://devforum.roblox.com/t/2388156" target="_blank">
													<span className="rounded shadow-sm fi fi-de"></span> German
												</Dropdown.Item>
												<Dropdown.Item href="https://devforum.roblox.com/t/2388146" target="_blank">
													<span className="rounded shadow-sm fi fi-gb"></span> English
												</Dropdown.Item>
											</DropdownButton>
										</Stack>
									</Card.Footer>
								</Card>
							</Col>
							<Col xs={12} sm={6} className="mb-4 d-flex align-items-stretch">
								<Card>
									<Card.Img variant="top" src="/img/report-card.png" />
									<Card.Body>
										<Card.Title>Report Player</Card.Title>
										<Card.Text>
											Please use this form to report any game rule violations in Emergency Hamburg
											to us immediately.
										</Card.Text>
									</Card.Body>
									<Card.Footer>
										<NavLink to="/player-report">
											<Button variant="danger">
												<i className="bi bi-flag-fill" /> Report Player
											</Button>
										</NavLink>
									</Card.Footer>
								</Card>
							</Col>
							<Col xs={12} sm={6} className="mb-4 d-flex align-items-stretch">
								<Card>
									<Card.Img variant="top" src="/img/unban-card.png" />
									<Card.Body>
										<Card.Title>Request Unban</Card.Title>
										<Card.Text>
											You can use this form if you think you have been banned wrongfully in
											Emergency Hamburg.
										</Card.Text>
									</Card.Body>
									<Card.Footer>
										<NavLink to="/unban-request">
											<Button variant="dark">
												<i className="bi bi-hammer" /> Request Unban
											</Button>
										</NavLink>
									</Card.Footer>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
