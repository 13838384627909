import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Stack from "react-bootstrap/Stack";

export default function Header() {
	return (
		<Navbar className="mb-3 sticky-top" variant="dark" bg="dark" expand="lg">
			<Container>
				<Navbar.Brand as={Link} to="/">
					<img
						src="/img/logo.svg"
						height="45"
						className="d-inline-block align-top"
						alt="Emergency Hamburg"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse>
					<Nav className="me-auto">
						<Nav.Link
							href="https://www.roblox.com/games/7711635737"
							target="_blank"
							className="fw-bold">
							<i className="bi bi-controller" /> Game
						</Nav.Link>
						<Nav.Link
							href="https://wiki.emergency-hamburg.com/"
							target="_blank"
							className="fw-bold">
							<i className="bi bi-info-circle" /> Wiki
						</Nav.Link>

						<NavDropdown title="Forms" className="fw-bold">
							<NavDropdown.Item as={Link} to="/player-report">
								Player Reports
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/unban-request">
								Unban Requests
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Other" className="fw-bold">
							<NavDropdown.Item as={Link} to="/imprint">
								Imprint
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/terms-of-service">
								Terms of Service
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/privacy-policy">
								Privacy Policy
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
					<Nav>
						<Nav.Item>
							<Stack direction="horizontal" gap={2}>
								<a
									href="https://www.discord.gg/emh"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-discord" />
								</a>
								<a
									href="https://www.tiktok.com/@emergencyhamburg"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-tiktok" />
								</a>
								<a
									href="https://x.com/emerghamburg"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-twitter-x" />
								</a>
								<a
									href="https://www.twitch.tv/emergencyhamburg"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-twitch" />
								</a>
							</Stack>
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
